export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,3,4,5,8,6];

export const dictionary = {
		"/": [10],
		"/(app)/acesso/criar": [~38,[2]],
		"/(app)/acesso/login": [~37,[2]],
		"/(app)/(auth)/admin/solicitacoes": [~12,[2,3]],
		"/(app)/(auth)/admin/usuarios": [~11,[2,3]],
		"/(app)/(auth)/(company)/cliente": [15,[2,3,4]],
		"/(app)/(auth)/(company)/cliente/edicao/[[id]]": [~16,[2,3,4]],
		"/(app)/(auth)/(company)/cliente/listar": [~17,[2,3,4]],
		"/(app)/(auth)/(company)/empresa/[id]": [~14,[2,3,4]],
		"/errors/[code]": [39],
		"/(app)/(auth)/perfil": [~13,[2,3]],
		"/(app)/(auth)/[umf]/[upa]/baldeio": [27,[2,3,5,8]],
		"/(app)/(auth)/[umf]/[upa]/baldeio/edicao/[[code]]": [~28,[2,3,5,8]],
		"/(app)/(auth)/[umf]/[upa]/baldeio/escanear/[code]": [30,[9]],
		"/(app)/(auth)/[umf]/[upa]/baldeio/listar": [~29,[2,3,5,8]],
		"/(app)/(auth)/[umf]/[upa]/dashboard": [21,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/etiquetas": [~18,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/inicio": [36,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/manejo/abate": [~23,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/manejo/arraste": [~24,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/manejo/cubagem": [~25,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/manejo/tora/[code]": [~26,[7]],
		"/(app)/(auth)/[umf]/[upa]/qrcode": [35,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/relatorios/inventario": [~34,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/relatorios/processamento": [~31,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/relatorios/scc": [~32,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/relatorios/toras": [~33,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/upa": [~22,[2,3,5]],
		"/(app)/(auth)/[umf]/[upa]/venda/edicao/[[id]]": [~19,[2,3,5,6]],
		"/(app)/(auth)/[umf]/[upa]/venda/listar": [~20,[2,3,5,6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';